export const masonryOptions = {
  itemSelector: '.face',
  isResizeBound: !1,
  columnWidth: 340,
  gutter: 32
}

export const IMG_STATE = {
  NORMAL: 'normal',
  HOVER: 'hover',
  VIDEO: 'video',
  BIG_IMG: 'big-img'
}