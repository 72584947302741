import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { win } from '@src/app-utils'

class OffsetAnchor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: false
    }

    this.updateDimensions = this.updateDimensions.bind(this)
  }

  updateDimensions() {
    if (win && window.innerWidth < 480) {
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  render() {
    const { isMobile } = this.state
    const offset = isMobile ? 50 : 75
    const style = {
      position: 'absolute',
      width: '0px',
      height: '0px',
      marginTop: `-${offset}px`
    }

    return (
      <a className="offsetAnchor" id={this.props.id} style={style}></a>
    )
  }
}

export default OffsetAnchor

OffsetAnchor.propTypes = {
  id: PropTypes.string.isRequired
}